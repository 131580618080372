import MapProfile, {Maps} from '@/sys/Model/MapProfile/MapProfile';
import BackEnd from '../../BackEnd/BanckEnd';
import { AccessLevel } from '@/sys/Model/Permissions/AccessLevel';

export default class MapProfilesControl {
  static listOfProfiles: Array<MapProfile> = [];
  static profilesLoaded: Boolean = false;
  static currentProfile: MapProfile;
  static currentMap: Maps;
  static panelsList = [];
  static usersToAdd = [];
  static panelsToPin = [];
  static newProfileName = '';
  static usersAvailable = [];
  static auxMapList = [];
  static auxNotFound = false;
  static fullUsersList = [];
  static isMovingPanel = false;


  static async fillListOfProfiles() {
    this.listOfProfiles = [];
    let profiles = await this.getUserProfiles();
    /* Check if user has profile */
    if (profiles.length === 0) {
      await this.createMapProfile({profileName: `Meu Perfil - ${BackEnd.getCurrentUser().customData.name}`});
      profiles = await this.getUserProfiles();
    }

    this.listOfProfiles = [...profiles]
    
    for (const profile of profiles) {
      if ( BackEnd.getCurrentUser().customData.currentMapProfile?.$oid == profile._id ) {
        this.setCurrentProfile(profile);
      } else if (!BackEnd.getCurrentUser().customData.currentMapProfile ) {
        await MapProfilesControl.setCurrentUserProfile(profile._id);
        this.setCurrentProfile(profile);
      }
    }

    if (
      !profiles.some(
        profile =>
          String(profile._id) ===
          String(BackEnd.getCurrentUser().customData.currentMapProfile?.$oid),
      )
    ) {
      this.setCurrentProfile(profiles[0]);
    }

    BackEnd.syncToCollection(
      'MapProfiles',
      {
        'unity.userList': {
          $elemMatch: {_id: BackEnd.getCurrentUser().customData._id},
        },
        deletedAt: null,
      },
      this.listOfProfiles,
    );
    this.profilesLoaded = true;
    this.callbackChangedListOfProfile();
  }
  static async getMapsBySelectedProfile() {
    try {
      if (!MapProfilesControl.profilesLoaded) {
        async () => {
          await MapProfilesControl.fillListOfProfiles;
        };
      }
    } catch (error) {
      throw error;
    }
  }

  static async getUsersBySelectedProfile() {
    try {
      if (!MapProfilesControl.profilesLoaded) {
        async () => {
          await MapProfilesControl.fillListOfProfiles;
        };
      }

      return this.currentProfile?.users;
    } catch (error) {
      throw error;
    }
  }

  private static async callRealmMapProfileControl(
    action: string,
    data: Object,
  ) {
    return await BackEnd.function().callFunction('mapProfileControl', {
      action,
      data,
    });
  }

  static async createMapProfile(data: any) {
    const resp = await BackEnd.function().callFunction('mapProfileControl', {
      action: 'createProfile',
      data,
    });

    await MapProfilesControl.setCurrentUserProfile(resp.data.profileCreated.insertedId);

    if(resp.success){
      const profiles = await this.getUserProfiles();
      this.setCurrentProfile(profiles[profiles.length - 1])
    }
  }

static async addMapToProfile(data: any) {
  if (!data.profileId) {
    throw new Error('É obrigatório escolher um perfil!');
  }
  
  // Checa se o nome do mapa existe e o modifica se preciso
  let baseName = data.map.name;
  let newName = baseName;
  let counter = 1;
  
  //Encontra os nomes existentes e determina o suffixo disponível
  while (this.currentProfile.maps.some(map => `${map.name}` === `${newName}`)) {
    newName = `${baseName} - ${counter}`;
    counter++;
  }
  
  // Insere novo valor de nome para o objeto a ser passado ao beck
  data.map.name = newName;
  
  const resp = await this.callRealmMapProfileControl('addMapToProfile', data);
  if (resp.success) {
    const profiles = await this.getUserProfiles();
    const profile = profiles.filter(
      prof => `${prof._id}` === `${this.currentProfile._id}`,
    );
    this.setCurrentProfile(profile[0]);
    this.callbackChangedListOfProfile();
  }
}

  static async removeMapFromProfile(data: any) {
    // @herli - Fazer validações aqui
    if (!data.profileId) {
      throw new Error('É obrigatório escolher um perfil!');
    }
    const resp = await this.callRealmMapProfileControl(
      'removeMapFromProfile',
      data,
    );

    if (resp.success) {
      this.currentProfile.maps = this.currentProfile.maps.filter(
        map => `${map._id}` != `${data.mapId}`,
      );
      this.auxMapList = this.currentProfile.maps.filter(
        map => `${map._id}` != `${data.mapId}`,
      );

      if (this.currentProfile.maps.length != 0) {
        this.currentMap = this.currentProfile.maps[0];
      }

      this.callbackChangedProfileMap();
      this.callbackChangedListOfProfile();
    }
  }

  static async editCurrentMap(data: any) {
    const checkName = this.currentProfile.maps.some(
      map => `${map.name}` === `${data.name}`,
    );
  
    if (checkName) {
      this.callbackChangedListOfProfile();
      throw new Error('Nome inserido já existe!');
    }

    if (!data.profileId) {
      throw new Error('É obrigatório escolher um perfil!');
    }
    const resp = await this.callRealmMapProfileControl('updateMap', data);

    if (resp.success) {
      const profiles = await this.getUserProfiles();
      const profile = profiles.filter(
        prof => `${prof._id}` === `${this.currentProfile._id}`,
      );
      this.setCurrentProfile(profile[0]);
      this.callbackChangedListOfProfile();
    }
  }

  static async addUserToProfile(data: any) {
    // @herli - Fazer validações aqui
    if (!data.profileId) {
      throw new Error('É obrigatório escolher um perfil!');
    }

    try {
      const resp = await this.callRealmMapProfileControl(
        'addUserToProfile',
        data,
      );
      if (resp.success === true) {
        const newUsersList = [...this.currentProfile.users, ...data.userList];
        this.currentProfile.users = [];
        this.currentProfile.users = newUsersList;
        this.callbackChangedListOfProfile();
      }
    } catch (error) {
      console.error(`Add User Error: ${error}`);
    }
  }

  static async updateProfileName(data: any) {
    if (!data.profileId) {
      throw new Error('É obrigatório escolher um perfil!');
    }

    try {
      const dataForAction = {
        profileId: data.profileId,
        newProfileName: data.newProfileName,
      };

      const resp = await this.callRealmMapProfileControl(
        'updateProfileName',
        dataForAction,
      );

      if (resp.success === true) {
        let dataForControler = data.currentProfile;
        dataForControler.name = data.newProfileName;
        this.currentProfile = dataForControler;
        this.callbackChangedListOfProfile();
      }
    } catch (error) {
      console.error(`Add User Error: ${error}`);
    }
  }

  static async removeUserFromProfile(data: any) {
    // @herli - Fazer validações aqui
    if (!data.profileId) {
      throw new Error('É obrigatório escolher um perfil!');
    }
    await this.callRealmMapProfileControl('removeUserFromProfile', data);
    const newUsersList = this.currentProfile.users.filter(
      user => `${user._id}` != data.userId,
    );
    this.currentProfile.users = newUsersList;
    this.callbackChangedListOfProfile();
  }

  static async deleteProfile(data: any) {
    // @herli - Fazer validações aqui
    if (!data.profileId) {
      throw new Error('É obrigatório escolher um perfil!');
    }
    await this.callRealmMapProfileControl('deleteProfile', data);
    await this.fillListOfProfiles();
  }

  static async addPanelsToMap(data: any) {
    this.currentMap.panels = [...this.currentMap.panels, ...data.panels];
    this.callbackChangedProfileMap();
    
    await this.callRealmMapProfileControl('addPanelsToMap', data);
  }

  static async movePanel(data: any) {    
    if (this.isMovingPanel) {
      throw new Error('Move operation already in progress');
    }
  
    this.isMovingPanel = true;
  
    try {
      // First update the backend
      const resp = await this.callRealmMapProfileControl('movePanel', {
        profileId: this.currentProfile._id,
        selectedMapId: data.selectedMapId,
        panelId: data.panel.device._id,
        x: data.panel.x,
        y: data.panel.y,
      });
  
      if (!resp.success) {
        throw new Error('Failed to update panel position on server');
      }
  
      // If backend update succeeds, update local state
      const panelIndex = this.currentMap.panels.findIndex(
        panel => `${panel._id}` === `${data.panel.device._id}`,
      );
  
      if (panelIndex === -1) {
        throw new Error('Panel not found in current map');
      }
  
      this.currentMap.panels[panelIndex].x = data.panel.x;
      this.currentMap.panels[panelIndex].y = data.panel.y;
  
      const mapIndex = this.currentProfile.maps.findIndex(
        map => `${map._id}` === `${this.currentMap._id}`,
      );
  
      if (mapIndex === -1) {
        throw new Error('Current map not found in profile');
      }
  
      const panelIndexProfile = this.currentProfile.maps[mapIndex].panels.findIndex(
        panel => `${panel._id}` === `${data.panel.device._id}`,
      );
  
      if (panelIndexProfile === -1) {
        throw new Error('Panel not found in profile map');
      }
  
      this.currentProfile.maps[mapIndex].panels[panelIndexProfile].x = data.panel.x;
      this.currentProfile.maps[mapIndex].panels[panelIndexProfile].y = data.panel.y;
      return resp;
    } finally {
      this.isMovingPanel = false;
    }
  }

  static async removePanelFromMap(data: any) {
    // @herli - Fazer validações aqui
    this.currentMap.panels = this.currentMap.panels.filter(
      panel => `${panel._id}` !== `${data.panel._id}`,
    );

    const mapIndex = this.currentProfile.maps.findIndex(
      map => `${map._id}` === `${this.currentMap._id}`,
    );
    this.currentProfile.maps[mapIndex].panels = this.currentProfile.maps[
      mapIndex
    ].panels.filter(panel => `${panel._id}` !== `${data.panel._id}`);

    setTimeout(() => {
      this.callbackChangedProfileMap();
    }, 1000);

    await this.callRealmMapProfileControl('removePanelFromMap', {
      profileId: this.currentProfile._id,
      selectedMapId: data.selectedMapId,
      panelId: data.panel._id,
    });
  }

  static async removeAllPanelsFromMap() {
    // @herli - Fazer validações aqui
    if (!this.currentProfile._id) {
      throw new Error('É obrigatório escolher um perfil!');
    }

    if (!this.currentMap._id) {
      throw new Error('É obrigatório escolher um mapa!');
    }

    this.currentMap.panels = [];
    const mapIndex = this.currentProfile.maps.findIndex(
      map => `${map._id}` === `${this.currentMap._id}`,
    );
    this.currentProfile.maps[mapIndex].panels = [];
    this.callbackChangedProfileMap();

    const data = {
      profileId: this.currentProfile._id,
      mapId: this.currentMap._id,
    };
    await this.callRealmMapProfileControl('removeAllPanelsFromMap', data);
  }

  static async getUserProfiles() {
    let resp = await this.callRealmMapProfileControl('getUserProfiles', {
      userId: BackEnd.getCurrentUser().customData._id,
    });

    let currentUserId = BackEnd.getCurrentUser().customData._id
    let currentAccessLvl = BackEnd.getCurrentUser().customData.accessLevel.$numberInt

    if(resp.success){
      if(currentAccessLvl <= AccessLevel.VENDAS){
        return resp.data.userProfiles;
      }else{
        let profilesList = [];

        resp.data.userProfiles.forEach(profile => {
          let found = profile.users.filter(user => user._id.toString() === currentUserId);
          if(found.length > 0){
            profilesList.push(profile);
          }
        });
        return profilesList
      }
    }
      
  }

  static getCurrentProfile() {
    return this.currentProfile;
  }

  static setCurrentProfile(profile) {
    this.currentProfile = profile;
    this.auxMapList = profile?.maps;

    if (
      this.currentMap === undefined ||
      this.currentProfile.maps.length === 1
    ) {
      this.currentMap = profile?.maps[0];
      this.panelsToPin = profile?.maps[0]?.panels;
    } else {
      const map = this.currentProfile?.maps.filter(
        map => `${map._id}` === `${this.currentMap._id}`,
      );
      this.currentMap = map[0];
    }

    this.callbackChangedPanelsToPin();
    this.callbackChangedProfileMap();
  }

  static async getAllActiveUsers() {
    const resp = await BackEnd.function().callFunction(
      'readAllActiveUsers',
      BackEnd.getCurrentUser().customData.accessLevel,
    );
    return resp;
  }

  static async myByPass() {
    const resp = await BackEnd.function().callFunction('readAllActiveUsers', 1);
    return resp;
  }

  static async setCurrentUserProfile(profileId: any) {
    await this.callRealmMapProfileControl('setCurrentUserProfile', {profileId});
  }

  static refreshPanelsList(realPanelsList) {
    this.panelsList = realPanelsList;

    this.callbackChangedRealPanelsList();
  }

  static async setDefaultUserViewMode(data: any) {
    await this.callRealmMapProfileControl('setDefaultUserViewMode', data);
  }

  /* Listener for listOfProfiles and maps */
  static listenerChangedListOfProfiles = [];

  static addListenerChangedListOfProfile(listener) {
    this.listenerChangedListOfProfiles.push(listener);
  }

  static removeListenerChangedListOfProfile(listener) {
    let index = this.listenerChangedListOfProfiles.indexOf(listener);
    if (index >= 0) {
      this.listenerChangedListOfProfiles.splice(index, 1);
    }
  }

  static callbackChangedListOfProfile() {
    this.listenerChangedListOfProfiles.forEach(listener => {
      listener();
    });
  }

  /* Listener for profiles and maps */
  static listenerChangedProfileMap = [];

  static addListenerChangedProfileMap(listener) {
    this.listenerChangedProfileMap.push(listener);
  }

  static removeListenerChangedProfileMap(listener) {
    let index = this.listenerChangedProfileMap.indexOf(listener);
    if (index >= 0) {
      this.listenerChangedProfileMap.splice(index, 1);
    }
  }

  static callbackChangedProfileMap() {
    this.listenerChangedProfileMap.forEach(listener => {
      listener();
    });
  }

  /* Listener for real panel data */
  static listenerChangedRealPanelsList = [];

  static addListenerChangedRealPanelsList(listener) {
    this.listenerChangedRealPanelsList.push(listener);
  }

  static removeListenerChangedRealPanelsList(listener) {
    let index = this.listenerChangedRealPanelsList.indexOf(listener);
    if (index >= 0) {
      this.listenerChangedRealPanelsList.splice(index, 1);
    }
  }

  static callbackChangedRealPanelsList() {
    this.listenerChangedRealPanelsList.forEach(listener => {
      listener();
    });
  }

  /* Listener for panels to pin */
  static listenerChangedPanelsToPin = [];
  static addListenerChangedPanelsToPin(listener) {
    this.listenerChangedPanelsToPin.push(listener);
  }
  static removeListenerChangedPanelsToPin(listener) {
    let index = this.listenerChangedPanelsToPin.indexOf(listener);
    if (index >= 0) {
      this.listenerChangedPanelsToPin.splice(index, 1);
    }
  }
  static callbackChangedPanelsToPin() {
    this.listenerChangedPanelsToPin.forEach(listener => {
      listener();
    });
  }

  static hasMapsPanelsStructure(obj) {
    // Get all keys of the object
    const keys = Object.keys(obj);
    
    // Check if we have both x and y coordinates
    const hasCoordinates = keys.some(key => {
        // Using regex to match the pattern
        const pattern = /^maps\.\d+\.panels\.\d+\.([xy])$/;
        return pattern.test(key);
    });

    return hasCoordinates;
}
}
